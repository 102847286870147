import React, { useEffect, useRef, useState } from "react";
import firstReviewImg from "../../images/1.svg";
import secReviewImg from "../../images/2.svg";
import thirdReviewImg from "../../images/3.svg";
import { Box, Typography, makeStyles } from "@material-ui/core";
import "./index.css";

const CarouselScreen = () => {
  const styles = useStyles();
  const containerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const timerRef = useRef(null);
  const startScrollTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const totalWidth = containerRef.current.scrollWidth;
      const scroll = containerRef.current.scrollLeft;
      const { width } = containerRef.current.getBoundingClientRect();
      if (scroll + width + 10 < totalWidth) {
        containerRef.current.scrollLeft += width * 0.8;
      } else {
        containerRef.current.scrollLeft = 0;
      }
      setCurrentTime(new Date().getTime())
    }, 3000);
  }

  const onScroll = (e) => {
    setCurrentTime(new Date().getTime());
    clearTimeout(timerRef.current);
  }

  useEffect(() => {
    startScrollTimer();
  }, [currentTime])
  return (
    <Box className={styles.container}>
      <Box id="ig" className={styles.carousalBox} component="div">
        <Typography className={styles.title}>Review</Typography>
        <Typography className={styles.subtitle}>Our Happy Customers</Typography>
        <Box className={styles.hor} />
        <div className={styles.carouselContainer} ref={containerRef} onScroll={onScroll}>
          <img className={styles.reviewImg} src={firstReviewImg} />
          <img className={styles.reviewImg} src={secReviewImg} />
          <img className={styles.reviewImg} src={thirdReviewImg} />
        </div>
      </Box>
    </Box>
  );
};
export default CarouselScreen;
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 36,
  },
  carouselContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
  },
  title: {
    color: '#03174C',
    fontSize: 28,
    lineHeight: 1.07,
    fontWeight: 600,
  },
  subtitle: {
    color: '#937DE2',
    marginTop: 4,
    fontSize: 18,
    fontWeight: 600,
  },
  hor: {
    marginBlock: 10,
    width: 34,
    border: '1px solid #937DE2',
    borderRadius: 4,
  },
  carousalBox: {
    background: '#fff',
    width: '80%',
    paddingInline: 36,
    paddingBlock: 44,
    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },
  },
  linesImg: {
    position: "absolute",
    marginTop: -180,
    left: 0,
    zIndex: -1,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobileLines: {
    position: "absolute",
    width: "100%",
    display: "none",
    zIndex: -1,
    left: 0,
    marginTop: -180,
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  reviewImg: {
    marginRight: 78,

    '&:first-child': {
      // marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: 190,
    },
  },
}));
