import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { APP_FEATURE_IMAGES } from "../../constants";
const AppFeature = () => {
  const styles = useStyles();
  return (
    <Box display="flex" className={styles.container}>
      <Typography className={styles.title}>
        APP FEATURES
      </Typography>
      <div className={styles.hor} />
      <Box className={styles.imageGrid}>
        <Box className={styles.featureContainer}>
          <img className={styles.featureImage} src={APP_FEATURE_IMAGES.STORIES} alt='Bedtime stories' />
          <Typography className={styles.featureText}>Bedtime stories</Typography>
        </Box>
        <Box className={styles.featureContainer}>
          <img className={styles.featureImage} src={APP_FEATURE_IMAGES.YOG_NIDRA} alt='Yog nidra' />
          <Typography className={styles.featureText}>Yog nidra</Typography>
        </Box>
        <Box className={styles.featureContainer}>
          <img className={styles.featureImage} src={APP_FEATURE_IMAGES.MEDITATION} alt={"Guided\nmeditations"} />
          <Typography className={styles.featureText}>{"Guided\nmeditations"}</Typography>
        </Box>
        <Box className={styles.featureContainer}>
          <img className={styles.featureImage} src={APP_FEATURE_IMAGES.MUSIC} alt='Calming music' />
          <Typography className={styles.featureText}>Calming music</Typography>
        </Box>
        <Box className={styles.featureContainer}>
          <img className={styles.featureImage} src={APP_FEATURE_IMAGES.REGIONAL_LANGUAGES} alt={"Regional\nLanguages"} />
          <Typography className={styles.featureText}>{"Regional\nLanguages"}</Typography>
        </Box>
        <Box className={styles.featureContainer}>
          <img className={styles.featureImage} src={APP_FEATURE_IMAGES.REGULAR_ADDITIONS} alt={"Regular New\nadditions"} />
          <Typography className={styles.featureText}>{"Regular New\nadditions"}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default AppFeature;
const useStyles = makeStyles((theme) => ({
  container: {
    background: '#fff',
    paddingTop: 55,
    paddingBottom: 76,
    flexDirection: 'column',
    alignItems: 'center',
  },
  hor: {
    border: '2px solid #775BDC',
    borderRadius: 4,
    width: 54,
    marginBlock: 24,
  },
  title: {
    color: '#03174C',
    fontSize: 28,
    lineHeight: 1.28,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  subtitle: {
    color: '#03174C',
    fontSize: 24,
    marginTop: 4,

  },
  featureImage: {
    height: 172,
    width: 176,
    borderRadius: 8,
  },
  featureText: {
    marginTop: 12,
    fontSize: 22,
    lineHeight: 1.27,
    fontWeight: 600,
    color: '#000',
    textAlign: 'center',
    maxWidth: 150,
  },
  imageGrid: {
    marginTop: 24,
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto auto',
    columnGap: 12,
    rowGap: 16,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto auto auto',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto auto',
      columnGap: 8,
    }
  },
  featureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));