import AppFooter from "../../components/AppFooter";
import Navbar from "../../components/Navbar";

const InternationalShippingPolicy = () => {
  return (<div>
    <Navbar />
    <div className="privacy-body">
      <h1 className="title">
        International Shipping Policy
      </h1>
      <div>
        <p className="subtitle"><b>Do you offer international shipping?</b></p>
        <p><span style={{ fontWeight: 400 }}>Yes, we offer international shipping at additional charge of INR 2500.</span></p>
        <p><span style={{ fontWeight: 400 }}>If you are not satisfied with what you have bought, we'll gladly return the product money. Shipping charge is not refundable.</span></p>
        <p><span style={{ fontWeight: 400 }}>Please note, Neend is not responsible for additional customs clearance charges, VAT or taxes charged by your country.</span></p>
      </div>
    </div>
    <AppFooter />
  </div>)
}

export default InternationalShippingPolicy;