import { Box, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { WEB_APP_CONTENTS_BASE_URL } from "../../constants";

const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}
const StatsAndReviews = (props) => {
  const styles = useStyles();

  const containerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const timerRef = useRef(null);
  const startScrollTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const totalWidth = containerRef.current.scrollWidth;
      const scroll = containerRef.current.scrollLeft;
      const { width } = containerRef.current.getBoundingClientRect();
      if (scroll + width + 10 < totalWidth) {
        containerRef.current.scrollLeft += width * 0.8;
      } else {
        containerRef.current.scrollLeft = 0;
      }
      setCurrentTime(new Date().getTime())
    }, 3000);
  }

  const onScroll = (e) => {
    setCurrentTime(new Date().getTime());
    clearTimeout(timerRef.current);
  }

  useEffect(() => {
    startScrollTimer();
  }, [currentTime])


  const storeLink = useMemo(() => getOS() === 'iOS' || 'Mac OS' ? 'https://apps.apple.com/in/app/neend-relax-sleep-meditate/id6444904431' : 'https://play.google.com/store/apps/details?id=com.neend', [])

  return <Box className={styles.container}>
    <img src={`${WEB_APP_CONTENTS_BASE_URL}/lazy-young.png`} alt="Happy Customer" className={styles.youngLazyImg} />
    <Box className={styles.statsWrapper} ref={containerRef} onScroll={onScroll} >
      <Box className={styles.statsContainer} >
        <Box width={192}>
          <Typography className={styles.happyUserPer}>90%</Typography>
          <Typography className={styles.usersText}>of our users</Typography>
          <Typography className={styles.experienceText}>experiencing better <br /> sleep quality!</Typography>
        </Box>
        <Box className={styles.statContainer}>
          <Typography className={styles.firstRowText}>750K+</Typography>
          <img src={`${WEB_APP_CONTENTS_BASE_URL}/android.png`} alt='Android' className={styles.androidLogo} />
          <Typography className={styles.thirdRowText}>Downloads</Typography>
        </Box>
        <Box className={styles.statContainer}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Typography className={styles.firstRowText} marginBottom={0}>4.5
            </Typography>
            <Typography className={styles.starText}>
              &#9733;
            </Typography>
          </Box>
          <img src={`${WEB_APP_CONTENTS_BASE_URL}/google-play.png`} alt='Play Store' className={styles.playStoreLogo} />
          <Typography className={styles.thirdRowText}>Rating</Typography>
        </Box>
        <Box className={styles.statContainer}>
          <Typography className={styles.firstRowText}>20Mn+</Typography>
          <img src={`${WEB_APP_CONTENTS_BASE_URL}/youtube.png`} alt='Youtube' className={styles.youtubeLogo} />
          <Typography className={styles.thirdRowText}>Views</Typography>
        </Box>
        <Box className={styles.bePartContainer}>
          <Typography className={styles.partText}>Be the part of
            <br />
          </Typography>
          <Typography className={styles.familyText}>
            our sleepy family!
          </Typography>
          <a className={styles.downloadButton} href={storeLink} target="_blank" rel="noreferrer">
            <Typography className={styles.buttonText}>
              Download App
            </Typography>
          </a>
        </Box>
      </Box>
    </Box>
  </Box>
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    marginBlock: 55
  },
  statsWrapper: {
    width: '80%',
    overflowX: 'auto',
    display: 'flex',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  statsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '80%',
    justifyContent: 'space-evenly',
    border: '1px solid #fff',
    borderTop: 'none',
    boxSizing: 'border-box',
    paddingBlock: 24,
    paddingInline: 37,
    minWidth: 1120,
    [theme.breakpoints.down('lg')]: {
      border: 'none',
    }
  },
  youngLazyImg: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: '100vw',
      objectFit: 'cover',
      objectPosition: '20%'
    }
  },
  androidLogo: {
    width: 29,
  },
  playStoreLogo: {
    width: 86,
  },
  youtubeLogo: {
    width: 69,
  },
  firstRowText: {
    fontSize: 34,
    fontWeight: 600,
    color: '#937DE2',
    marginBottom: 9,
  },
  thirdRowText: {
    fontSize: 12,
    color: '#fff',
    marginTop: 1,
  },
  statContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url("${WEB_APP_CONTENTS_BASE_URL}/swirling-circles.png")`,
    backgroundSize: '100%',
    width: 185,
    height: 185,
    justifyContent: 'center',
    marginLeft: 20,
  },
  happyUserPer: {
    fontSize: 36,
    fontWeight: 600,
    color: '#fff',
  },
  usersText: {
    fontSize: 22,
    lineHeight: 1.3,
    fontWeight: 600,
    color: '#fff',
  },
  experienceText: {
    fontSize: 18,
    color: '#F8E71C',
  },
  bePartContainer: {
    marginLeft: 19,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  partText: {
    fontSize: 20,
    lineHeight: 1.4,
    color: '#fff',
    width: 190,
    textAlign: 'center'
  },
  familyText: {
    fontSize: 20,
    lineHeight: 1.4,
    color: '#F8E71C',
  },
  downloadButton: {
    marginTop: 15,
    width: 174,
    height: 36,
    borderRadius: 50,
    background: '#937DE2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center'
  },
  starText: {
    color: '#937DE2',
    fontSize: 17,
    fontWeight: 600,
    marginBottom: 9,
    marginLeft: 2,
  }
}));
export default StatsAndReviews;