import React, { useRef, useState } from "react";
import AppFeature from "../../components/AppFeature";
import AppFooter from "../../components/AppFooter";
import CenterText from "../../components/Content";
// import MobileDrawer from "../../components/MobileDrawer";
import Navbar from "../../components/Navbar";
import ProductSection from "../../components/Products";
import CarouselScreen from "../../components/ReviewSection";
import "../../i18n";
import StatsAndReviews from "../../components/StatsnReviews";
import MediaMentions from "../../components/MediaMentions";

const Home = () => {
  const aboutRef = useRef(null);
  const [mobileDrawer, setMobileDrawer] = useState(false);

  return (
    <>
      <Navbar
        click={() => aboutRef.current.scrollIntoView()}
        onClick={() => setMobileDrawer(true)}
        active="Home"
      />
      {/* <MobileDrawer
        onClose={() => setMobileDrawer(false)}
        aboutUsHandler={() => {
          setMobileDrawer(false);
          setTimeout(() => {
            aboutRef.current.scrollIntoView();
          }, 500);
        }}
        click={() => aboutRef.current.scrollIntoView()}
        open={mobileDrawer}
      /> */}
      <CenterText />
      <AppFeature />
      <StatsAndReviews />
      <CarouselScreen />
      <MediaMentions />
      <ProductSection />
      <AppFooter />
    </>
  );
};

export default Home;
