export const CloudfrontBaseURL = 'https://d2z284jirfzmcj.cloudfront.net';

export const WEB_APP_CONTENTS_BASE_URL = `${CloudfrontBaseURL}/neena.app contents`;

export const APP_FEATURE_IMAGES = {
  MEDITATION: `${WEB_APP_CONTENTS_BASE_URL}/guided-meditations.png`,
  STORIES: `${WEB_APP_CONTENTS_BASE_URL}/bedtime-stories.png`,
  MUSIC: `${WEB_APP_CONTENTS_BASE_URL}/calming-music.png`,
  REGIONAL_LANGUAGES: `${WEB_APP_CONTENTS_BASE_URL}/regional-languages.png`,
  REGULAR_ADDITIONS: `${WEB_APP_CONTENTS_BASE_URL}/regular-additions.png`,
  YOG_NIDRA: `${WEB_APP_CONTENTS_BASE_URL}/yog-nidra.png`,
}