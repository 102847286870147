import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EmailUnsubscribe from "./Pages/EmailUnsubscribe";
import Home from "./Pages/Home";
import OurTeam from "./Pages/OurTeam";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";
import InternationalShippingPolicy from "./Pages/InternationalShippingPolicy";
import ReturnAndRefundPolicy from "./Pages/ReturnAndRefundPolicy";
import MobileAppPrivacy from "./Pages/MobileAppPrivacy";

const RedirectToPlayStore = () => {
  useEffect(() => {
    const anchor = document.createElement('a');
    anchor.href = 'https://play.google.com/store/apps/details?id=com.neend';
    anchor.click();
  }, []);

  return <div />
}

const NeendPage = ({ title, Page }) => {
  useEffect(() => {
    document.title = title ?? 'Neend App';
  }, []);

  return <Page />
}

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/team" element={<NeendPage Page={OurTeam} />} />
        <Route path="/unsubscribe" element={<NeendPage Page={EmailUnsubscribe} />} />
        <Route path="/privacy-policy" element={<NeendPage Page={PrivacyPolicy} title={'Privacy Policy – Neend'} />} />
        <Route path="/pages/privacy-policy" element={<NeendPage Page={PrivacyPolicy} title={'Privacy Policy – Neend'} />} />
        <Route path="/terms-of-service" element={<NeendPage Page={TermsOfService} title={'Terms of Service – Neend'} />} />
        <Route path="/pages/terms-of-service" element={<NeendPage Page={TermsOfService} title={'Terms of Service – Neend'} />} />
        <Route path="/international-shipping-policy" element={<NeendPage Page={InternationalShippingPolicy} title={'International Shipping Policy – Neend'} />} />
        <Route path="/pages/international-shipping-policy" element={<NeendPage Page={InternationalShippingPolicy} title={'International Shipping Policy – Neend'} />} />
        <Route path="/return-and-refund-policy" element={<NeendPage Page={ReturnAndRefundPolicy} title={'Return and Refund Policy – Neend'} />} />
        <Route path="/pages/return-and-refund-policy" element={<NeendPage Page={ReturnAndRefundPolicy} title={'Return and Refund Policy – Neend'} />} />
        <Route path="/mobile-app-privacy-policy" element={<NeendPage Page={MobileAppPrivacy} title={'Mobile App Privacy Policy – Neend'} />} />
        <Route path="/pages/mobile-app-privacy-policy" element={<NeendPage Page={MobileAppPrivacy} title={'Mobile App Privacy Policy – Neend'} />} />
        <Route path="/" element={<NeendPage Page={Home} />} />
        <Route path="*" element={<NeendPage Page={RedirectToPlayStore} />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Routing;
