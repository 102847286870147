import AppFooter from "../../components/AppFooter";
import Navbar from "../../components/Navbar";

const TermsOfService = () => {

  return (
    <div>
      <Navbar />
      <div className="privacy-body">
        <h1 className="title">
          Terms of Service
        </h1>
        <div>
          <h1 data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">OVERVIEW</b></h1>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>This website is operated by Purple Bot Technologies Private Limited. Throughout the site, the terms “we”, “us” and “our” refer to Purple Bot Technologies Private Limited. Purple Bot Technologies Private Limited offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>By visiting our site and/ or purchasing something from us, you engage in our “Service(s)” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. These Terms of Service include a requirement to arbitrate claims on an individual, rather than class-wide, basis. For more information, see section 14.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Please read these Terms of Service carefully before accessing or using our Services. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Services following the posting of any changes constitutes acceptance of those changes.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 1 - ONLINE STORE TERMS</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>A breach or violation of any of the Terms will result in an immediate termination of your Services.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 2 - PRODUCTS OR SERVICES (if applicable)</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Certain products or Services may be available exclusively online through the website. These products or Services are subject to return or exchange only according to our<span>&nbsp;</span></span><a href="https://goli.com/policies/refund-policy" data-sanitized-data-mce-fragment="1" data-sanitized-data-mce-href="https://goli.com/policies/refund-policy"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Return Policy</span></a><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>All orders of our products must be for personal use only, and the resale of our products by or through any third-party owned or operated websites, online auction, third party online marketplace, store or digital platform (including but not limited to Amazon, Etsy, Shopify, eBay, Alibaba or other similar sites) is strictly prohibited. By purchasing our products, you agree not to resell or distribute our products for any commercial purposes. We reserve the right to reject or cancel any order, including but not limited to orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors, and if we have reason to believe that your order is not for personal use.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction, and to limit the quantities of any products or Services that we offer. We may exercise this right on a case-by-case basis.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>All descriptions of products or product pricing are subject to change at any time without notice, at our sole discretion. We reserve the right to discontinue any product at any time. Any offer for any product or Service made on this site is void where prohibited.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We do not warrant that the quality of any products, Services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected. We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 3 - MODIFICATIONS TO THE SERVICE AND PRICES</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Prices for our products are subject to change without notice.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 4 - GENERAL CONDITIONS</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We reserve the right to refuse service to anyone for any reason at any time.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided, without our express written permission.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 5 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may, in our sole discretion, cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the email and/or billing address/phone number provided at the time the order was made.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>For more detail, please review our<span>&nbsp;</span></span><a href="https://goli.com/policies/refund-policy" data-sanitized-data-mce-fragment="1" data-sanitized-data-mce-href="https://goli.com/policies/refund-policy"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Return Policy</span></a><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 7 - OPTIONAL TOOLS</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You acknowledge and agree that we provide access to such tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may also, in the future, offer new services and/or features to the current store or through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 8 - THIRD-PARTY LINKS</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Certain content, products and services available via our Service may include materials from third-parties.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third party.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>If, at our request, you send certain specific submissions (for example contest entries) or, without a request from us, you send creative ideas, reviews, testimonials, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You agree that your comments will not violate any right of any third party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false email address, pretend to be someone other than yourself, or otherwise mislead us or third parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third party.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 10 - PERSONAL INFORMATION</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Your submission of personal information through the store is governed by our<span>&nbsp;</span></span><a href="https://goli.com/policies/privacy-policy" data-sanitized-data-mce-fragment="1" data-sanitized-data-mce-href="https://goli.com/policies/privacy-policy"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Privacy Policy</span></a><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 12 - PROHIBITED USES</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit worms, viruses or any other type of destructive or malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure or error-free.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You agree that from time to time we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>In no case shall Purple Bot Technologies Private Limited, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or any products procured using the Service, or for any other claim related in any way to your use of the Service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Service or any content (or product) posted, transmitted, or otherwise made available via the Service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 14 - DISPUTE RESOLUTION; ARBITRATION AGREEMENT</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Any dispute or claim arising out of or relating in any way to the website, our representations, these Terms of Service, or the Services will be resolved exclusively by final and binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. Also, this Arbitration Agreement will not preclude any action for injunctive relief in aid of arbitration. This Arbitration Agreement applies to any dispute or claim between the parties and/or their parents, subsidiaries, employees, agents, officers, or owners (who shall be third-party beneficiaries of this provision). The Federal Arbitration Act and federal arbitration law apply to this Arbitration Agreement.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>BY AGREEING TO THIS ARBITRATION AGREEMENT, YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT, INCLUDING YOUR RIGHT TO A JURY TRIAL. The arbitration will be conducted by a neutral arbitrator, rather than by a judge or jury. The arbitrator can only award on an individual basis and must rely on these Terms of Service. The arbitration will be administered by the American Arbitration Association ("AAA") and conducted under AAA's Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (collectively, the "AAA Rules") then in effect at the time of the dispute. The AAA’s rules are available at www.adr.org or by calling 1-800-778-7879. Either party may initiate arbitration by providing written notice to the other party and filing a demand with the AAA. Payment of all filing, administration and arbitrator fees will be governed by the AAA’s rules. We will reimburse those fees to the AAA for claims totaling less than $10,000 unless the arbitrator determines the claims are frivolous or brought for an improper purpose. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. You do not have the right to act as a class representative or participate as a member of a class of claimants with respect to any claim submitted to arbitration.</b><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span>&nbsp;</span>The arbitrator may not consolidate more than one person's claims against a party and may not preside over any kind of representative or class proceeding. You acknowledge that this class action waiver is material and essential to the arbitration of any disputes between the parties and is non-severable from the agreement to arbitrate claims. YOU UNDERSTAND THAT BY AGREEING TO THIS CLASS ACTION WAIVER, YOU MAY ONLY BRING CLAIMS AGAINST US IN AN INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. IF YOU HAVE ALREADY USED THE WEBSITE OR SERVICES AND YOU DO NOT AGREE TO THE FOREGOING TERMS AND CONDITIONS, YOU MUST TELL US IN WRITING AND STOP USING THE WEBSITE AND SERVICES.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 15 - INDEMNIFICATION</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You agree to indemnify, defend and hold harmless Gummies and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 16 - SEVERABILITY</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 17 - TERMINATION</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 18 - ENTIRE AGREEMENT</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 19 - GOVERNING LAW</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Subject to the Arbitration Agreement in section 14 herein, these Terms of Service shall be governed by and construed in accordance with the laws of the State of New York, without regard to any conflicts of law provisions. You expressly agree and personally submit to the exclusive jurisdiction of the state and federal courts of the State of New York to adjudicate and resolve any dispute with Purple Bot Technologies Private Limited, its affiliates, subsidiaries, employees, contractors, officers, directors, telecommunication providers and content providers or in any other way relating to the website, including, content or user content. YOU HEREBY IRREVOCABLY WAIVE YOUR RIGHT TO A JURY TRIAL OR TO CLAIM THAT THE STATE OF NEW YORK IS AN INCONVENIENT FORUM TO HEAR CLAIMS AND DISPUTES.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 20 - CHANGES TO TERMS OF SERVICE</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You can review the most current version of the Terms of Service at any time at this page.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</span></p>
          <br data-sanitized-data-mce-fragment="1" />
          <p data-sanitized-data-mce-fragment="1"><b data-sanitized-data-mce-fragment="1">SECTION 21 - CONTACT INFORMATION</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Questions about the Terms of Service should be sent to us at care@neend.app.</span></p>
        </div>
      </div>
      <AppFooter />
    </div>
  )
}

export default TermsOfService;
