import {
  Fade
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WEB_APP_CONTENTS_BASE_URL } from "../../constants";
import "./Navbar.css";
import "./ToggleButton.css";

const Navbar = (props) => {
  // const styles = useStyles(props);
  const [navPosition, setNavPosition] = useState(false);
  const { window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  const navigate = useNavigate();
  useEffect(() => {
    setNavPosition(trigger);
  }, [trigger]);
  const openTeams = () => navigate('/team');
  const navigateHome = () => navigate('/');

  const toggleMobileNav = () => {
    document.getElementsByClassName('mobile-navigation')[0].classList.toggle('visible')
  }

  useEffect(() => {
    const clickListener = (e) => {
      if (
        !document
          .getElementsByClassName("mobile-navigation")?.[0]
          ?.contains(e.target) &&
        !e.target.classList.contains("nav-icon") &&
        !e.target.classList.contains("nav-icon-line")
      ) {
        document
          .getElementsByClassName("mobile-navigation")[0]
          .classList.remove("visible");
      }
    }
    document.addEventListener("click", clickListener);
    return () => document.removeEventListener('click', clickListener)
  }, [])

  return (
    <Fade in={!navPosition}>
      <nav id="navbar">
        <div
          className="nav-icon"
          onClick={toggleMobileNav}
        >
          <span className="nav-icon-line"></span>
          <span className="nav-icon-line"></span>
          <span className="nav-icon-line"></span>
        </div>
        <a href="/" className="logo-anchor">
          <img
            src={`${WEB_APP_CONTENTS_BASE_URL}/neend-logo.png`}
            alt="Neend"
            className="logo"
            loading="lazy"
          />
        </a>
        <div className="left-header">
          <div className={`navLink ${props.active === 'Home' ? 'active' : ''}`} onClick={navigateHome}><span>HOME</span> </div>
          <div className={`navLink ${props.active === 'Team' ? 'active' : ''}`} onClick={openTeams}><span>TEAM</span></div>
          <a className="navLink" href="https://shop.neend.app/"><span>SHOP</span></a>
        </div>
        <div className="mobile-navigation">
          <div className={`navLink ${props.active === 'Home' ? 'active' : ''}`} onClick={navigateHome}><span>HOME</span> </div>
          <div className={`navLink ${props.active === 'Team' ? 'active' : ''}`} onClick={openTeams}><span>TEAM</span></div>
          <a className="navLink" href="https://shop.neend.app/"><span>SHOP</span></a>
        </div>
        <div className="icons-container">
          <a
            href="https://play.google.com/store/apps/details?id=com.neend&utm_source=website"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0613/5339/4325/files/play_store_logo.png?v=1683978262"
                alt="Play Store Icon"
                className="icons"
                loading="lazy"
              />
            </div>
          </a>
          <a
            style={{ marginLeft: "12px" }}
            href="https://apps.apple.com/us/app/neend-relax-sleep-meditate/id6444904431?id=com.neend&utm_source=website"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0613/5339/4325/files/app_store_logo.png?v=1683978322"
                alt="App Store Icon"
                className="icons"
                loading="lazy"
              />
            </div>
          </a>
        </div>
      </nav>
    </Fade>
  );
};

const useStyles = makeStyles((theme) => ({
  headerView: {
    backgroundColor: "#03174C",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingInline: 24,
    alignItems: "center",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      paddingInline: 12,
      paddingBlock: 8,
    },
  },
  storeAnchor: {
    padding: 12,
  },
  playStoreIcon: {
    width: 20,
    height: 20,
  },
  appStoreIcon: {
    width: 20,
    height: 24,
    marginTop: -4
  },
  navRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  navHeading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
  leftNav: {
    flexWrap: "nowrap",
  },
  navHomeText: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: (props) => (props?.active === "Home" ? 18 : 14),
    fontStyle: "normal",
    fontWeight: 600,
    color: (props) => (props?.active === "Home" ? "#937DE2" : "#fff"),
    borderBottom: (props) => (props?.active === "Home" ? '3px solid #937DE2' : 'none'),
    "&:hover": {
      color: "#937de2",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconButton: {
    display: 'none',
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: 0,
      display: "block",
    },
  },
  navButtons: {
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    "&:hover": {
      color: "#937de2",
    },
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 600,
    fontStyle: "normal",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  teamButton: {
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    color: (props) => (props?.active === "Team" ? "#937DE2" : "#fff"),
    "&:hover": {
      color: "#937de2",
    },
    cursor: "pointer",
    fontSize: (props) => (props?.active === "Team" ? 18 : 14),
    fontWeight: 600,
    fontStyle: "normal",
    borderBottom: (props) => (props?.active === "Team" ? '3px solid #937DE2' : 'none'),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuIconStyle: {
    paddingInline: 12,
  },
  navLink: {
    height: '100%',
    marginLeft: 34,
    cursor: 'pointer',
  }
}));

export default Navbar;
