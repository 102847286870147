import AppFooter from "../../components/AppFooter";
import Navbar from "../../components/Navbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-body">
        <h1 className="title">
          Privacy Policy
        </h1>
        <div>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Please read this Privacy Policy carefully to understand our policies and practices regarding your Personal Data (as defined below) and how we will treat it.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>This Privacy Policy applies to the Purple Bot Pvt Ltd games, apps, websites and related services (together the “Services” or “games”, “apps”, “websites” for individual categories). This Privacy Policy explains how we, and affiliated companies (collectively, "Purple Bot Pvt Ltd " or "we", "us", "our") collect, use, secure, and/or disclose end users’ (“you” or “your”) or otherwise processes your personal information when you use our Services.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The data controller is Purple Bot Pvt Ltd , with a registered address at 3-Ta-1, near post office, Jawahar Nagar, Jaipur, Rajasthan 302004 . Purple Bot Pvt Ltd as Data Controller does not and will not process any special categories of personal data (“sensitive data”).</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Purple Bot Pvt Ltd as Data Controller</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Information and Data we Collect from you</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>By using our Services you agree to our Privacy Policy and confirm that you are at least 16 years old.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>While using our Services, we may ask you to provide us with certain personally identifiable information that can be used to identify you (“Personal Data”). Our Services are designed to avoid collecting information such as your name, address, email address or other similar contact data unless you provide such data to us. The information we collect include:</span></p>
          <ul data-sanitized-data-mce-fragment="1">
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Automatically collected data (such as IP Address, your device’s operating system, browser type and language)</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Mobile device identifiers (such as your unique device ID (persistent/non-persistent), IDFA (Identifier for advertisers), hardware type</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>General location information for the purpose of providing you with the correct version of the application</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Precise-geo-location information from your mobile device (but not in all our applications and games and only with your express consent where applicable)</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Applications and games data (such as your interactions with the application or game and with the other users or players inside the application or game respectively via server log files, your user or player ID)</span></li>
          </ul>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>How We Use Your Information</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Purple Bot Pvt Ltd’s primary goals in using information are to provide Services to you, improve our Service, contact you, conduct marketing and research and create reports for internal use. We store information on servers located in the United States and India via Amazon Web Services and may store information on servers and equipment in other countries.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We collect information automatically about your activity through our Services, for example date and time you used a service, features you have used, in-app purchase history, subscriptions, and advertising that you click on, and data generated when you use our Services, for example your game progress which may be stored with your Apple ID on iCloud for iOS Devices in some of our Services. Our Services may also contain tools from third party service providers that may enable these third parties to separately collect and/or analyze non-Personal Data from the users' devices.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may use your information to:</span></p>
          <ul data-sanitized-data-mce-fragment="1">
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Operate and improve our Service</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Understand you and your preferences to enhance your experience and enjoyment using our Service</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Respond to your comments and questions and provide customer service</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Provide and deliver products and services you request</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Deliver advertising and marketing and promotional information to you</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Send you related information, including confirmations, invoices, technical notices, updates, push notifications, security alerts, and support and administrative messages</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Communicate with you about promotions, rewards, upcoming events, and other news about products and services offered by Purple Bot Pvt Ltd and our selected partners</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Link or combine it with other information we get from third parties, to help understand your preferences and provide you with better services.</span></li>
          </ul>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The legal basis for us to use your information is the agreements you enter into with us in connection with the Services (the “Terms of Use”). Our employees have access to your information only to the extent their functions are required to provide our Service or otherwise act on our behalf as set forth in this Policy.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Other Information</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>With only your express consent, we or our partners may collect your precise location based information (GPS) for the purpose of tailoring content specific to your area (such as area based content packages), and to match your device location to certain points of interest for purposes of interest based advertising as described elsewhere in this Policy. Please note, participation in this part of the Service is not a requirement for all our applications and games. You may at any time opt-out from sharing your location by turning it off at the device level through your settings.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Information We Obtain from Third Parties</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may receive information about you from our analytics service providers with data they collect through our Services in accordance with their own privacy policies. A list of the third parties that operate in our Services can be found in the Appendix A below.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Information We Obtain from Facebook</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>If you use our Services on Facebook, we may receive any of your information you provide or permit to be provided to us via Facebook. Such data may include your user ID and your use of our game, including with whom you share our game on Facebook and your email address in case you wish to contact us for support. The information we receive depends on the Services you are using, Facebook terms of service and privacy policy, your privacy settings, and, if applicable, your friends’ privacy settings.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The information we receive is stored and used by us in accordance with this Privacy Policy. If you are uncertain about what information Facebook is sharing with us, please go to the website of Facebook to find out more about their privacy practices.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Customer Inquiries</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may use your email address when you contact our customer service group to contact you about your application usage and gaming experience with Purple Bot Pvt Ltd applications and games and notify you about company news and promotions.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>If you no longer wish to receive certain email notifications you may opt-out at any time by following the unsubscribe link located at the bottom of each communication or by emailing us with the contact information provided in this policy.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Tracking and Data Collection Technologies</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We and our marketing and outsourcing partners, affiliates, or analytics service providers use technologies such as cookies, beacons, scripts, and tags to identify a user’s computer/device and to “remember” things about your visit, such as your preferences or a user name and password. Information contained in a cookie may be linked to your personal information, such as your user ID, for purposes such as improving the quality of our Services, tailoring recommendations to your interests, and making the Service easier to use. You can disable cookies at any time.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Website Services</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>While you are browsing the Sites, your computer’s operating system, Internet Protocol (IP) address, access times, browser type and language and referring Web site addresses may be logged automatically. We may use this information to monitor, develop and analyse your use of the Service.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may use your email address to contact you about your experience with the Services and notify you about Purple Bot Pvt Ltd news and promotions.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Mobile Applications</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>When you use any of the Purple Bot Pvt Ltd applications or games on a mobile platform, we may record certain information and data, such as your application usage, access, session times and in-app purchases, your unique device ID (persistent / non-persistent), hardware type, the version of your operating system (“OS”), your device name, and your location (based on your Internet Protocol (“IP”) address). This information is useful to us for troubleshooting and helps us understand usage trends and improve and optimize the Services and your user experience, and may also be utilized for interest based advertising as described elsewhere in this Policy.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may occasionally send you push notifications through our mobile applications about application updates, new content availability and other service related that may be of importance to you. You may at any time opt-out from receiving these types of communications by turning them off at the device level through your settings.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Rights of Data Subjects</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You have the right to request the access and rectification or erasure of personal data or restriction of processing or to object to processing as well as the right to data portability. Further, you have a right to lodge a complaint with a respective supervisory authority.</span></p>
          <ul data-sanitized-data-mce-fragment="1">
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The right to rectification is your right to obtain without undue delay the rectification of inaccurate personal data concerning you</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>The right to erasure is your right to obtain the erasure of personal data concerning you without undue delay, where certain additional circumstances are fulfilled</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Under certain circumstances you have the right to restrict the processing of personal data;</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You have the right to receive the personal data concerning you, and that you have provided to us, and have the right to transmit those data to another controller, where technically feasible, where the processing is based on consent or on a contract and the processing is carried out by automated means</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You have the right to object, on grounds relating to your particular situation, at any time to processing of personal data concerning you which is based on respective provisions of the law, including profiling based on those provisions</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Subject to the respective statutory limitations set by the applicable law, you have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you;</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Where the processing of personal data is based on your consent you can withdraw such consent at any time, without affecting the lawfulness of processing based on consent prior to its withdrawal.</span></li>
          </ul>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Please use Surbhi@neend.app to exercise all of the aforementioned rights.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Accessing, Correcting, and Deleting Your Information</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Upon request, we will provide you with information about whether we hold any of your personal information. If your personal information changes, or if you no longer desire our Services, you may correct, update, delete inaccuracies, or delete your personal information by making the change within your account settings or by contacting us. We will respond to your access request within a reasonable timeframe.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Opting Out of Marketing</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>You may opt-out of receiving promotional emails from us by following the instructions in those emails by emailing us with the contact information provided in this policy. If you opt-out, we may still send you non-promotional emails, such as emails about your accounts or our ongoing business relations.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Data Security and Retention Periods</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Keeping Personal Data secure is a top most priority for Purple Bot Pvt Ltd . We do our best to protect your information from unauthorized access or against loss, misuse or alteration by third parties, disclosure and other improper processing activities.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We have put in place reasonable and appropriate organisational, technical and physical procedures and safeguards to protect the information we collect and process. These procedures also take into account the type of personal data and the risks related to its possible disclosures. In the following we bring out specific actions with regard to organisational, technical and physical safeguards in place at Purple Bot Pvt Ltd .</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Data Retention</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We will also retain Usage Information for internal analytics purposes. Usage Information is generally retained for a shorter period of time, except when this data is used to improve the functionality of our Services, or we are legally obligated to retain this data for longer time periods.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Your Personal Data collected through our Services will be deleted when they no longer serve one of the above-mentioned purposes but in any event your Personal Data will be deleted after 14 months of inactivity in your use of our Services. Once we have deleted your Personal Data, you will not be able to exercise the right to access, the right to erasure, the right to rectification or the right to data portability.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Our Policy Regarding Children</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We appreciate the need to provide extra privacy protections to users who are children. Our Services are intended for general audiences over the age of 16 years. We do not knowingly collect or solicit personal information from or direct or target interest based advertising to anyone under the age of 16 or knowingly allow such persons to use our Services. If you are under 16, please do not send any information about yourself to us, including your name, address, telephone number, or email address. No one under the age of 16 may provide any personal information. In the event that we learn that we have collected personal information from a child under age 16, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under the age of 16, please contact us.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Third Party Services</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Our Services may contain third party tracking and data collection and analytics tools from our service providers, examples of which include Firebase, Appsflyer, Segment, Branch. A list of the third parties that operate in our Services can be found in the Appendix A below. Such third parties may use cookies, APIs, and SDKs in our Services to enable them to collect and analyze user and device related data and information on our behalf. The third parties may have access to and collect data and information about you, such as your device identifier, IDFA (Identifier for advertisers), locale (specific location where a given language is spoken), geo-location information, IP address, application usage, access and session times, applications present on the device or in use at a particular time on the device, and your viewing of and interactions with advertising, for the purpose of providing their services, including, for example, enabling, providing and delivering advertising as described in further detail below. The privacy policies of our service providers may include additional terms and disclosures regarding their data collection and use practices and tracking technologies, and we encourage you to check those privacy policies to learn more about their data collection and use practices, use of cookies and other similar tracking technologies.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>How We May Disclose Your Information to Third Parties</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Purple Bot Pvt Ltd does not share your personal information except as approved by you or as described below:</span></p>
          <ul data-sanitized-data-mce-fragment="1">
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Purple Bot Pvt Ltd may engage other companies and individuals to perform services on our behalf. An example of these services may include analyzing data and providing customer support. These agents and service providers may have access to your personal information in connection with the performance of services for Purple Bot Pvt Ltd .</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may release your information as permitted by law, such as to comply with a subpoena, or when we believe that release is appropriate to comply with the law; investigate fraud, respond to a government request, enforce or apply our rights; or protect the rights, property, or safety of us or our users, or others. This includes exchanging information with other companies and organizations for fraud protection. In certain situations, Purple Bot Pvt Ltd may be required to disclose personal information in response to lawful requests by public authorities or for local law enforcement requirements.</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Purple Bot Pvt Ltd may share your information in connection with any merger, sale of our assets, or a financing or acquisition of all or a portion of our business to another company. You will be notified via email and/or notice on our Sites of any change in ownership or users of your personal information.</span></li>
            <li data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Apple iOS users may opt-in to allow our Services to provide data regarding the amount of minutes spent within Purple Bot Pvt Ltd applications to the Apple iOS “Health” application for display. This data will not be shared with third parties or used for marketing purposes.</span></li>
          </ul>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We may share aggregate or anonymous information about you with advertisers, publishers, business partners, sponsors, and other third parties.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Links to Other Sites</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>For the convenience of our visitors and clients, the Website and application may contain links to other sites, such as those of our partners or vendors, which are subject to different privacy policies. The Policy will not apply to your use of other websites. While we generally try to link only to sites that share similar high standards and respect for privacy, we have no responsibility or liability for the content, products or services offered, independent actions, or the privacy and security practices employed by these other independent sites. We encourage you to ask questions and review the applicable privacy policies found on such other websites, services and applications to understand how your information may be collected and used on these independent sites before disclosing information to third parties.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Changes to This Privacy Policy</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>This Privacy Policy may change from time to time, and we encourage you to review it periodically. Your continued use of our Services signifies your acceptance of changes to this Privacy Policy.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>In some cases, we may notify you of changes to this Privacy Policy with a prominent notice on our Services (for example by way of a notification or pop-up), before the changes become effective and update the "effective date" at the top of this Privacy Policy.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>APPENDIX A: Analytics and Other Service Providers</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We use your information to perform our own analytics and to enable analytics provided by third parties and other essential functions. We use analytical information for supporting business analysis and operations, business intelligence, product development, improving the Services, personalizing content, providing advertising, and making recommendations. In order to learn about how your information is used by our analytics service providers, you can follow the hyperlinks in the list below to each provider’s privacy notice.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Appsflyer</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Appsflyer is an analytics service provided by Appsflyer Ltd (Israel)</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Privacy Policy: https://www.appsflyer.com/privacy-policy/</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Facebook Ads Conversion</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Facebook Ads conversion tracking is an analytics service provided by Facebook, Inc. (US) that connects data from the Facebook advertising network with actions performed on this Application.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Privacy Policy: https://www.facebook.com/about/privacy/</span></p>
        </div>
      </div>
      <AppFooter />
    </div>)
};

export default PrivacyPolicy;