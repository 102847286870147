import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import "./AboutNeend.css";
import neend from "../../images/Neend.png";
import fb from "../../images/facebook.svg";
import linkedin from "../../images/linkedin.svg";
import instagram from "../../images/instagram.svg";
import google from "../../images/googlePlay.svg";
import twitter from "../../images/twitter.svg";
import spotify from "../../images/spotify.png";
import Gana from "../../images/gaana.png";
import saavn from "../../images/saavn.png";
import whatsApp from "../../images/whatsApp.png";
import email from "../../images/email.png";
import youTube from "../../images/youtube.svg";
import AppFooter from "../AppFooter";

const About = (props) => {
  const styles = useStyles();

  return (
    <AppFooter ref={props.aboutRef} />
  );
};

export default About;
const useStyles = makeStyles((theme) => ({
  aboutSectionBox: {
    paddingInline: 239,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingInline: 40,
    },
  },

  aboutSectionText: {
    color: "#fff",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 14,
    width: 581,
    marginBlock: 18,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  neendLogo: {
    width: 68.65,
    height: 54.47,
  },
  linkDiv: {
    marginBlockEnd: 17.17,
    display: "flex",
    flexDirection: "row",
  },
  appInfoText: {
    marginBlockStart: 26.97,
    color: "#fff",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: 14,
  },
  socialBox: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  socialIcons: {
    marginBlockStart: 38,
  },
  socialIconDiv: {
    marginTop: 23,
  },
  emailIcon: {
    height: 20,
    width: 28,
    marginRight: 17,
    marginLeft: 62,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 32,
      height: 13,
      width: 21,
    },
  },
  whatsAppIcon: {
    height: 27,
    width: 27,
    marginRight: 17,
    [theme.breakpoints.down("sm")]: {
      height: 20,
      width: 20,
      marginRight: 17,
    },
  },
  contactSection: {
    alignItems: "center",
  },
  contactText: {
    color: "#fff",
    fontFamily: "Open Sans",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
