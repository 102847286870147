import AppFooter from "../../components/AppFooter";
import Navbar from "../../components/Navbar";

const MobileAppPrivacy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-body">
        <h1 className="title">
          Mobile App Privacy Policy
        </h1>
        <div>
          <p><span style={{ fontWeight: 400 }}>Your privacy is extremely important to us! This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://shop.neend.app.</span></p>
          <p><span style={{ fontWeight: 400 }}>The terms "You," "Your," "Yours" and "User" refer to the entity/person/organization using our site. When this Policy mentions "We", "Us," and "Our" it refers to Neend and its subsidiaries and affiliates. “Site” refers to https://shop.neend.app and its android and iOS mobile app</span></p>
          <p><span style={{ fontWeight: 400 }}>This Privacy Policy is governed by our Terms of Services.</span></p>
          <p><span style={{ fontWeight: 400 }}>For any questions regarding this Policy or any requests regarding the processing of personal data, please contact us at care@neend.app.</span></p>
          <h2 ><b>1. INFORMATION WE COLLECT FROM YOU</b></h2>
          <p><span style={{ fontWeight: 400 }}>We collect the information You provide us and this information is necessary for the adequate performance of the contractual arrangement which is in place between You and us and allow us to comply with our legal obligations.</span></p>
          <ul>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Account Signup Information. When You create the account, we ask You to provide the signup information, such as Email, Name, Surname, Phone, Username, Passwords, Personal Number, Address.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Communication, chats, messages. When you communicate with us through email or any other way, we collect information about your communication and any information You choose to provide or disclose. In order to answer your request, we may access information provided by email, chats, purchase history, etc.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Payment Information. To order and use features of Site, we may require you to provide certain financial information in order to facilitate the processing of payments. We collect your Credit or debit card number, Credit or debit card type, Credit or debit card expiration date, Billing address, Tax number, Name and surname.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Login information. We collect Login information if You are logging to our account with Authentication Data.</span></li>
          </ul>
          <h2 ><b>2. INFORMATION WE COLLECT AUTOMATICALLY</b></h2>
          <p><span style={{ fontWeight: 400 }}>When you use our Site or contact us directly we may collect information, including your personal information, about the way you act in our Site, the services You use and how You use them.</span></p>
          <p><span style={{ fontWeight: 400 }}>This information is necessary for the adequate performance of the contract between You and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Site.</span></p>
          <ul>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Log data and Device information. We automatically collect log data and device information when you access and use the site, even if you have not created an Account or logged in. That information includes, among other things: Internet protocol (IP) addresses, Browser type, Internet service provider (ISP), Referring/exit pages, Operating system, Date/time stamp, Clickstream data.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Tracking technologies and Cookies. We use Cookies, Beacons, Tags, CI codes (click tracking), ISC (source tracking), ITC (item tracking codes), Phone model, Device ID, Customer number. We also automatically collect information about the device's operating system .</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Geo-location data. We collect information about your approximate location as determined by data such as your IP address to offer you an improved user experience. Such data may be collected only when you access the Site using your device.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Usage information. We use a tool called "Google Analytics" to collect information about your interactions with the Site (what pages you visit, such as the pages or content you view, your searches for Listings, bookings you have made, and other actions on the Site. In consequence, Google, Inc. plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this Site). For more information please visit Google.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Publicly available personal Information.</span></li>
          </ul>
          <h2 ><b>3. THE WAY WE USE YOUR INFORMATION</b></h2>
          <p><span style={{ fontWeight: 400 }}>We process your information adhering to the general data processing principles.</span></p>
          <p><span style={{ fontWeight: 400 }}>We may use the information we collect through our Site for a number of reasons, including to:</span></p>
          <ul>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to identify user</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to create account</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to create trusted environment</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to create statistics and analyze market</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to stay connected</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to customize marketing</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to send billing information</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to manage user orders</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to contact user</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to improve services</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to ensure data security and prevent fraud</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to comply with applicable laws</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to request feedback</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to post testimonials</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to provide support</span></li>
          </ul>
          <p><span style={{ fontWeight: 400 }}>We will normally collect personal information from you only where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate business interests.</span></p>
          <h2 ><b>4. DIRECT MARKETING</b></h2>
          <p><span style={{ fontWeight: 400 }}>We may use your provided contact details for direct marketing. These direct marketing offers, depending on your preferences, may be personalized taking into account any other information which you have provided to us (e.g. location, social media profile information, etc.) or we have collected or generated from other sources as described below.</span></p>
          <p><span style={{ fontWeight: 400 }}>If you wish to withdraw the consent for direct marketing, and refuse to receive information from us, you may exercise such an option at any time you wish by updating your preferences in your account, following the instructions to unsubscribe in the received email.</span></p>
          <h2 ><b>5. HOW WE MAY SHARE YOUR INFORMATION</b></h2>
          <p><span style={{ fontWeight: 400 }}>Site may send user information like email address to its mobile app provider Vajro and to Shopify if you use social login (Facebook, Google, Apple) or a one time code.</span></p>
          <p><span style={{ fontWeight: 400 }}>Site may use (currently or in the future) analytical tools like </span><a href="https://policies.google.com/privacy?hl=en-US"><span style={{ fontWeight: 400 }}>Google Analytics</span></a><span style={{ fontWeight: 400 }}>, </span><a href="https://policies.google.com/privacy"><span style={{ fontWeight: 400 }}>Firebase Analytics</span></a><span style={{ fontWeight: 400 }}>, </span><a href="https://clevertap.com/privacy-policy/"><span style={{ fontWeight: 400 }}>Clevertap</span></a><span style={{ fontWeight: 400 }}> and </span><a href="https://www.appsflyer.com/trust/privacy/"><span style={{ fontWeight: 400 }}>Appsflyer</span></a><span style={{ fontWeight: 400 }}>.</span></p>
          <p><span style={{ fontWeight: 400 }}>We may also disclose your information to third parties:</span></p>
          <ul>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>where required by law or regulatory requirement, court order or other judicial authorization;</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>in response to lawful requests by public authorities, including for the purposes of meeting national security and law enforcement requirements;</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>in connection with the sale, transfer, merger, bankruptcy, restructuring or other reorganization of a business;</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>to protect or defend our rights, interests or property, or that of third parties; (e) to investigate any wrongdoing in connection with our products and services;</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>and to protect the vital interests of an individual.</span></li>
          </ul>
          <h2 ><b>6. COOKIES</b></h2>
          <p><span style={{ fontWeight: 400 }}>Cookies are small text files stored by your browser on your computer when you visit our Site. We use cookies to improve our Site and make it easier to use. Cookies permit us to recognize users and avoid repetitive requests for the same information.</span></p>
          <p><span style={{ fontWeight: 400 }}>Cookies from our Site cannot be read by other Sites. Most browsers will accept cookies unless you change your browser settings to refuse them.</span></p>
          <p><span style={{ fontWeight: 400 }}>Cookies we use on our Site:</span></p>
          <ul>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Strictly necessary cookies - These cookies are required for the operation of our Site. They help us to show you the right information, customize your experience, and allow us to implement and maintain security features as well as to help us detect malicious activities. Without these cookies operation of the Site would be impossible or its functioning may be severely affected.</span></li>
          </ul>
          <p><span style={{ fontWeight: 400 }}>You may find more information about how to delete cookies, as well as the other useful information related to the use of the cookies, on the website http://www.allaboutcookies.org/.</span></p>
          <h2 ><b>7. SENSITIVE INFORMATION</b></h2>
          <p><span style={{ fontWeight: 400 }}>We do not collect sensitive information such as political opinions, religious or philosophical beliefs, racial or ethnic origin, genetic data, biometric data, health data or data related a sexual orientation.</span></p>
          <p><span style={{ fontWeight: 400 }}>Please do not send, upload, or provide us any sensitive data and contact us using the contact details below if you believe that we might have such information. We have a right to delete any information we believe might contain sensitive data.</span></p>
          <h2 ><b>8. PAYMENT INFORMATION</b></h2>
          <p><span style={{ fontWeight: 400 }}>Please refer to the privacy policy which is available in our website https://shop.neend.app.</span></p>
          <h2 ><b>9. THIRD PARTY LINKS</b></h2>
          <p><span style={{ fontWeight: 400 }}>Our Site may have links to other websites. Please review their privacy policies to learn more about how they collect and use your personal data, because we do not control their policies and personal data processing practices.</span></p>
          <h2 ><b>10. RETENTION</b></h2>
          <p><span style={{ fontWeight: 400 }}>We retain your personal information to provide services to you and as otherwise necessary to comply with our legal obligation, resolve disputes, and enforce our agreements.</span></p>
          <p><span style={{ fontWeight: 400 }}>We will retain your personal information as long as we need it to provide services to you, unless we are otherwise required by law or regulations to retain your personal information longer.</span></p>
          <h2 ><b>11. SECURITY</b></h2>
          <p><span style={{ fontWeight: 400 }}>We have implemented security measures designed to protect the personal information you share with us, including physical, electronic and procedural measures. Among other things, we regularly monitor our systems for possible vulnerabilities and attacks.</span></p>
          <p><span style={{ fontWeight: 400 }}>Regardless of the measures and efforts taken by us, the transmission of information via the internet, email or text message is not completely secure. We do not guarantee the absolute protection and security of your personal information or any other User Content you upload, publish or otherwise share with us or anyone else.</span></p>
          <p><span style={{ fontWeight: 400 }}>We therefore encourage you to avoid providing us or anyone with any sensitive information of which you believe its disclosure could cause you substantial or irreparable harm.</span></p>
          <p><span style={{ fontWeight: 400 }}>If you have any questions regarding the security of our Site or Services, you are welcome to contact us at care@neend.app.</span></p>
          <h2 ><b>12. YOUR RIGHTS</b></h2>
          <p><span style={{ fontWeight: 400 }}>You are entitled to a range of rights regarding the protection of your personal information. Those rights are:</span></p>
          <ul>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The right to access the information we have about you. If you wish to access your personal information that we collect, you can do so at any time by contacting us at care@neend.app</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The right to rectify inaccurate information about you. You can correct, update or request deletion of your personal information by contacting us using the contact details provided below.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The right to object to the processing. When we rely on your consent to process your personal information, you may withdraw consent at any time by contacting us using the contact details provided below. This will not affect the lawfulness of processing prior to the withdrawal of your consent.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The right to lodge a complaint. You can raise questions or complaints to the national Data Protection Agency in your country of residence in the event where your rights may have been infringed. However, we recommend attempting to reach a peaceful resolution of the possible dispute by contacting us first.</span></li>
            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The right to erase any data concerning you. You may demand erasure of data without undue delay for legitimate reasons, e.g. where data is no longer necessary for the purposes it was collected, or where the data has been unlawfully processed.</span></li>
          </ul>
          <p><span style={{ fontWeight: 400 }}>If you choose to delete your account and all associated data (or specific parts of it), send an email to care@neend.app requesting deletion of data. We'll process your request and delete all account data within (x [Max: 30]) days. You will receive an email confirmation as soon as it is done.</span></p>
          <h2 ><b>13. APPLICATION OF POLICY</b></h2>
          <p><span style={{ fontWeight: 400 }}>This Policy applies only to the services offered by our Company. Our Policy does not apply to services offered by other companies or individuals, including products or sites that may be displayed to you in search results, sites that may include our services or other sites linked from our Site or Services.</span></p>
          <h2 ><b>14. AMENDMENTS</b></h2>
          <p><span style={{ fontWeight: 400 }}>Our Policy may change from time to time. We will post any Policy changes on our Site and, if the changes are significant, we may consider providing a more explicit notice (including, for certain services, email notification of Policy changes).</span></p>
          <h2 ><b>15. ACCEPTANCE OF THIS POLICY</b></h2>
          <p><span style={{ fontWeight: 400 }}>We assume that all Users of this Site have carefully read this document and agree to its contents. If someone does not agree with this Policy, they should refrain from using our Site. We reserve the right to change our Policy at any time and inform by using the way as indicated in Section 14. Continued use of this Site implies acceptance of the revised Policy.</span></p>
          <h2 ><b>16. FURTHER INFORMATION</b></h2>
          <p><span style={{ fontWeight: 400 }}>If you have any further questions regarding the data we collect, or how we use it, then please feel free to contact us at care@neend.app</span></p>
        </div>
      </div>
      <AppFooter />
    </div>
  )
};

export default MobileAppPrivacy;