import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { WEB_APP_CONTENTS_BASE_URL } from "../../constants";

const ProductSection = () => {
  const styles = useStyles();
  return (
    <Box display="flex" className={styles.container}>
      <Typography className={styles.title}>
        Products
      </Typography>
      <Typography className={styles.subtitle}>
        that help you rest easy, sleep better
      </Typography>
      <Box className={styles.hor} />
      <Box className={styles.productsContainer}>
        <img className={styles.productImg} src={`${WEB_APP_CONTENTS_BASE_URL}/products.png`} alt="Product Portfolio" />
        <Box className={styles.productFeatureContainer}>
          <Box className={styles.productNamesContainer}>
            <Typography className={styles.productNameText}>Gummies</Typography>
            <Box className={styles.nameSeparator} />
            <Typography className={styles.productNameText}>Tea</Typography>
            <Box className={styles.nameSeparator} />
            <Typography className={styles.productNameText}>Mask</Typography>
            <Box className={styles.nameSeparator} />
            <Typography className={styles.productNameText}>Spray</Typography>
          </Box>
          <Box className={styles.productFeaturesContainer}>
            <Box className={styles.featureContainer}>
              <Box className={styles.nameSeparator} />
              <Typography className={styles.productNameText}>Organic ingredients</Typography>
            </Box>
            <Box className={styles.featureContainer}>
              <Box className={styles.nameSeparator} />
              <Typography className={styles.productNameText}>Non-habit forming</Typography>
            </Box>
            <Box className={styles.featureContainer}>
              <Box className={styles.nameSeparator} />
              <Typography className={styles.productNameText}>No side effects</Typography>
            </Box>
          </Box>
          <a className={styles.downloadButton} href='https://shop.neend.app'>
            <Typography className={styles.buttonText}>
              ORDER NOW
            </Typography>
          </a>
        </Box>
      </Box>
    </Box>
  );
};
export default ProductSection;
const useStyles = makeStyles((theme) => ({
  container: {
    background: '#E3E6FF',
    paddingTop: 55,
    paddingBottom: 76,
    flexDirection: 'column',
    alignItems: 'center',
  },
  hor: {
    border: '2px solid #775BDC',
    borderRadius: 4,
    width: 54,
    marginBlock: 24,
  },
  title: {
    color: '#03174C',
    fontSize: 28,
    lineHeight: 1.28,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  subtitle: {
    color: '#03174C',
    fontSize: 24,
    marginTop: 4,
    textAlign: 'center',
  },
  productsContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  productImg: {
    width: 455,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  productFeatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 58,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 50,
    }
  },
  productNamesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
  },
  nameSeparator: {
    width: 6,
    height: 6,
    background: '#775BDC',
    borderRadius: 6,
  },
  productNameText: {
    fontSize: 20,
    lineHeight: 1.4,
    color: '#03174C',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: 1.75
    }
  },
  productFeaturesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    gap: 8,
  },
  featureContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  downloadButton: {
    marginTop: 32,
    width: 202,
    height: 50,
    borderRadius: 50,
    background: '#775BDC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center'
  },
}));
