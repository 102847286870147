import { Box, Typography, makeStyles } from "@material-ui/core";
import { WEB_APP_CONTENTS_BASE_URL } from "../../constants";
import { useEffect, useRef, useState } from "react";

const MediaMentionBaseUrl = `${WEB_APP_CONTENTS_BASE_URL}/media-mentions`
const AllMediaMentions = [
  { name: 'Your Story', img: `${MediaMentionBaseUrl}/your-story.png`, width: 84, height: 84, },
  { name: 'Mumbai Times', img: `${MediaMentionBaseUrl}/mumbai-times.png`, width: 67.6, height: 37.3, },
  { name: 'Open', img: `${MediaMentionBaseUrl}/open.png`, width: 71, height: 23, },
  { name: 'Her Story', img: `${MediaMentionBaseUrl}/her-story.png`, width: 84, height: 84, },
  { name: 'Mint Lounge', img: `${MediaMentionBaseUrl}/mint-lounge.png`, width: 84, height: 84, },
  { name: 'Lokmat', img: `${MediaMentionBaseUrl}/lokmat.png`, width: 77, height: 30, },
  { name: 'MediCircle', img: `${MediaMentionBaseUrl}/medicircle.png`, width: 77, height: 28, },
  { name: 'Entrackr', img: `${MediaMentionBaseUrl}/entrackr.png`, width: 77, height: 11.3, },
  { name: 'Express HealthCare', img: `${MediaMentionBaseUrl}/express-healthcare.png`, width: 67.6, height: 30, },
  { name: 'Pudhari', img: `${MediaMentionBaseUrl}/pudhari.png`, width: 51.3, height: 28, }
]

const MediaMentions = () => {
  const styles = useStyles();
  const containerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const timerRef = useRef(null);

  const startScrollTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const totalWidth = containerRef.current.scrollWidth;
      const scroll = containerRef.current.scrollLeft;
      const { width } = containerRef.current.getBoundingClientRect();
      if (scroll + width + 10 < totalWidth) {
        containerRef.current.scrollLeft += width * 0.8;
      } else {
        containerRef.current.scrollLeft = 0;
      }
      setCurrentTime(new Date().getTime())
    }, 3000);
  }

  const onScroll = (e) => {
    setCurrentTime(new Date().getTime());
    clearTimeout(timerRef.current);
  }

  useEffect(() => {
    const totalWidth = containerRef.current.scrollWidth;
    const { width } = containerRef.current.getBoundingClientRect();
    if (width < totalWidth) {
      startScrollTimer();
    }
  }, [currentTime])

  return (
    <Box display='flex' justifyContent='center' marginY={7}>
      <Box className={styles.container}>
        <Typography className={styles.title}>Media mentions in</Typography>
        <Box className={styles.hor} />
        <div className={styles.imagesWrapper} ref={containerRef} onScroll={onScroll}>
          <Box className={styles.imageContainer}>
            {AllMediaMentions.map(({ name, img, width, height }) => (<img style={{ width, height }} className={styles.imgStyle} key={name} src={img} alt={name} />))}
          </Box>
        </div>
      </Box>
    </Box>
  )
}

export default MediaMentions;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBlock: 24,
    paddingInline: 30,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    background: '#fff',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    alignSelf: 'center',
  },
  title: {
    color: '#03174C',
    fontSize: 28,
    lineHeight: 1.07,
    fontWeight: 600,
  },
  hor: {
    width: 34,
    height: 2,
    background: '#775BDC',
    borderRadius: 4,
    marginTop: 10,
    marginBottom: 14,
  },
  imagesWrapper: {
    width: '100%',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
  },
  imageContainer: {
    minWidth: 1000,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imgStyle: {
    height: 'auto',
    width: 'auto'
  }
}));