import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { WEB_APP_CONTENTS_BASE_URL } from "../../constants";
import apple from "../../images/app-store-banner.png";
import google from "../../images/googlePlay.svg";

const CenterText = () => {
  const styles = useStyles();

  return (
    <Box id="bg" className={styles.centerBox} component="div" display="flex">
      <img className={styles.bgImg} src={`${WEB_APP_CONTENTS_BASE_URL}/content-bg.png`} alt='mountains' />
      <img className={styles.appScreenshot} src={`${WEB_APP_CONTENTS_BASE_URL}/app-banner.png`} alt='app-screenshot' />
      <Box display='flex' flexDirection='column' alignItems='center' className={styles.secondColumn}>
        <Typography className={styles.ourDreamText}>Sleep better,<br /> in just 15 mins!</Typography>
        <Typography className={styles.helpingIndiaText}>
          Come, let’s tuck you in!
        </Typography>
        <div className={styles.neendApps}>
          <a
            href="https://play.google.com/store/apps/details?id=com.neend"
            rel="noreferrer"
          >
            <img className={styles.googlePlay} src={google} alt='play store' />
          </a>
          <a href="https://apps.apple.com/in/app/neend-relax-sleep-meditate/id6444904431"
            className={styles.appStoreAnchor}
            rel="noreferrer">
            <img className={styles.appStoreIcon} src={apple} alt='app store' />
          </a>
        </div>
      </Box>
    </Box>
  );
};
export default CenterText;
const useStyles = makeStyles((theme) => ({
  centerBox: {
    paddingTop: 20,
    maxWidth: '100%',
    overflowX: 'auto',
    alignItems: "center",
    flexDirection: "row",
    justifyContent: 'center',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    [theme.breakpoints.down("sm")]: {
      paddingTop: 26,
      fontSize: 28,
      textAlign: "center",
      // flexDirection: 'column'
    },
  },
  secondColumn: {
    marginLeft: 66,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
      // marginTop: 20,
    }
  },
  googlePlay: {
    height: 46,
    [theme.breakpoints.down("sm")]: {
      height: 32,
    },
  },
  appStoreAnchor: {
    marginLeft: 48,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 24,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: 0
    },
  },
  appStoreIcon: {
    height: 46,
    [theme.breakpoints.down("sm")]: {
      height: 32,
    },
  },
  appScreenshot: {
    // borderRadius: 4,
    width: '30%',
    [theme.breakpoints.down("xs")]: {
      marginLeft: -12,
      width: '60%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '100%',
    },
  },
  neendApps: {
    display: "flex",
    marginTop: 80,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column',
      marginTop: 8,
    },
  },
  featureContainer: {
    borderRadius: 200000,
    border: '1px dotted #937DE2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#03164C',
    height: 90,
    width: 90,
  },
  meditationContainer: {
    transform: 'rotate(-8deg) translateX(-60%) scale(1.3)',
  },
  musicContainer: {
    transform: 'rotate(-2deg) translateX(-40%)',
    zIndex: 1,
  },
  shopContainer: {
    transform: 'rotate(-8deg)',
  },
  storiesContainer: {
    zIndex: 1,
    transform: 'rotate(-8deg)',
  },
  featureText: {
    color: '#97A4C5',
    fontSize: 16,
  },
  ourDreamText: {
    textAlign: 'center',
    color: "#F8E71C",
    fontSize: 40,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Comfortaa",
    fontStyle: "normal",
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  helpingIndiaText: {
    color: "#fff",
    fontSize: 24,
    marginTop: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    }
  },
  msgText: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    width: 509,
    textAlign: "center",
    marginBlockEnd: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: 187,
    },
  },
  dotText: {
    color: "#937DE2",
    fontWeight: 400,
    fontSize: 20,
    marginBlockEnd: 32.54,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginBottom: 20,
    },
  },
  downloadImg: {
    [theme.breakpoints.down("sm")]: {
      width: 224,
    },
  },
  bgImg: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    width: "100%",
    bottom: 0,
    objectFit: 'cover',
    height: '100%',
    [theme.breakpoints.down("sm")]: {
      top: 66,
    },
  },
}));
