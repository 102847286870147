import AppFooter from "../../components/AppFooter";
import Navbar from "../../components/Navbar";

const ReturnAndRefundPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-body">
        <h1 className="title">
          Return and Refund Policy
        </h1>
        <div>
          <p data-sanitized-data-mce-fragment="1" className="subtitle"><b data-sanitized-data-mce-fragment="1">5 Day return policy</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>We offer you complete peace of mind while ordering at<span>&nbsp;</span></span><b data-sanitized-data-mce-fragment="1">Neend</b><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span>&nbsp;</span>- you can return items which qualify as returnable/refundable under this Policy within 5 days of receipt of goods. Please, ensure however that the product is<span>&nbsp;</span></span><b data-sanitized-data-mce-fragment="1">unused</b><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}><span>&nbsp;</span>and the tags, boxes and other packaging is intact.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>If you are not satisfied with what you have bought, we'll gladly take it back within 5 days from the date of delivery.</span></p>
          <p data-sanitized-data-mce-fragment="1" className="subtitle"><b data-sanitized-data-mce-fragment="1">Damages and issues</b><b data-sanitized-data-mce-fragment="1"><br data-sanitized-data-mce-fragment="1" /></b><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.</span></p>
          <p data-sanitized-data-mce-fragment="1" className="subtitle"><b data-sanitized-data-mce-fragment="1">How can I cancel my order?</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Cancellation of an order can be requested at any time before delivery of the order. If order is prepaid, you’ll be automatically refunded on your original payment method.</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>To cancel, please WhatsApp us at +91 73380 58468 or email at care@neend.app.&nbsp;</span></p>
          <p data-sanitized-data-mce-fragment="1" className="subtitle"><b data-sanitized-data-mce-fragment="1">Replacement of product</b></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>To be eligible for a replacement, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging.&nbsp;</span></p>
          <p data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>To initiate replacement, please WhatsApp us at +91 73380 58468 or email at care@neend.app. Our customer happiness executives might ask for additional information before processing the request.</span></p>
          <p data-sanitized-data-mce-fragment="1" className="subtitle"><b data-sanitized-data-mce-fragment="1">Following shall not be eligible for return or replacement:</b></p>
          <ol data-sanitized-data-mce-fragment="1">
            <li data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Damages due to misuse of product</span></li>
            <li data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Damages due to incorrect use of the product</span></li>
            <li data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Incidental damage due to malfunctioning of product</span></li>
            <li data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Any consumable item, which has been used or installed</span></li>
            <li data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Products with tampered or missing serial / UPC numbers</span></li>
            <li data-sanitized-data-mce-fragment="1"><span data-sanitized-data-mce-fragment="1" style={{ fontWeight: 400 }} data-sanitized-data-mce-style={{ fontWeight: 400 }}>Combos sold won't be taken back/returned as individual units</span></li>
          </ol>
        </div>
      </div>
      <AppFooter />
    </div>
  )
}

export default ReturnAndRefundPolicy;